//src\app\login\page.tsx
"use client";

import React, { useContext, useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import Image from "next/image";
import { signIn } from "next-auth/react";
import { AuthContext } from "@/context/AuthProvider";
import googleSvg from "@/images/Google.svg";
import appleSvg from "@/images/Apple.svg";

const loginSocials = [
  {
    name: "Continuer avec Google",
    icon: googleSvg,
    provider: "google"
  },
  {
    name: "Continuer avec Apple",
    icon: appleSvg,
    provider: "apple"
  }
] as const;

const Login = () => {
  const router = useRouter();
  const authContext = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);

  if (!authContext) {
    return null;
  }

  const { login } = authContext;

  const handlePHPLogin = async (userData: any) => {
    try {
      const response = await fetch("https://esi-fly.com/api/signin/index.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error(`Server API error: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.success) {
        if (data.partners) {
          login({
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            picture: data.picture,
            token: data.token,
            partners: data.partners
          });
        } else {
          login({
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            picture: data.picture,
          });
        }

        if (data.newUser) {
          try {
            await fetch("/api/send/welcome", {
              method: "POST",
              body: JSON.stringify({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
              }),
            });
          } catch (emailError) {
            console.error("Erreur lors de l'envoi de l'email:", emailError);
          }
        }
        return true;
      } else {
        throw new Error(data.message || "Erreur de connexion");
      }
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email || !password) {
      setError("Veuillez entrer votre email et votre mot de passe");
      return;
    }

    if (!acceptedPrivacy) {
      setError("Veuillez accepter les politiques de confidentialité");
      return;
    }

    try {
      setLoading(true);
      setError("");

      await handlePHPLogin({ email, password });
      router.push("/");
    } catch (err: any) {
      setError(err.message || "Une erreur s'est produite lors de la connexion");
      console.error("Login error:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSocialLogin = async (provider: string) => {
    try {
      setLoading(true);
      setError("");
      const userAgent = window.navigator.userAgent.toLowerCase();
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || userAgent.includes('mobile') || userAgent.includes('mobileios') || userAgent.includes('mobileandroid');
      
      await signIn(provider, {
        callbackUrl: `${window.location.origin}/oauth-callback`,
        popup: true,
        ...(isMobile && {
          redirect: true,
          flow: "implicit"
        })
      });
  
    } catch (err: any) {
      setError(err.message || "Une erreur s'est produite lors de la connexion");
      setLoading(false);
    }
  };

  return (
    <div className="nc-PageLogin">
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Se Connecter
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* Social Login Buttons */}
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <button
                key={index}
                onClick={() => handleSocialLogin(item.provider)}
                disabled={loading}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px] disabled:opacity-70"
              >
                <Image
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                  width={24}
                  height={24}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </button>
            ))}
          </div>

          {/* Divider */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OU
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>

          {/* Login Form */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            {error && <div className="text-red-500">{error}</div>}
            
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Adresse email
              </span>
              <input
                type="email"
                placeholder="exemple@exemple.com"
                className="mt-1 w-full rounded-lg border border-neutral-200 bg-white px-4 py-3 text-base font-normal text-neutral-700 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
              />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Mot de passe
                <Link href="/forgot-password" className="text-sm underline font-medium">
                  Mot de passe oublié?
                </Link>
              </span>
              <input
                type="password"
                className="mt-1 w-full rounded-lg border border-neutral-200 bg-white px-4 py-3 text-base font-normal text-neutral-700 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading}
              />
            </label>

            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-primary-6000 border-gray-300 rounded"
                checked={acceptedPrivacy}
                onChange={(e) => setAcceptedPrivacy(e.target.checked)}
                disabled={loading}
              />
              <span className="ml-2 text-sm text-neutral-700 dark:text-neutral-300">
                J'accepte les{" "}
                <Link href="/policy/single" className="underline">
                  politiques de confidentialité
                </Link>
              </span>
            </label>

            <button
              type="submit"
              disabled={loading || !acceptedPrivacy}
              className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-primary-50"
            >
              {loading ? (
                <>
                  <span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-primary-50 border-r-transparent"></span>
                  Connexion en cours...
                </>
              ) : (
                "Se Connecter"
              )}
            </button>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Nouveau utilisateur? {` `}
            <Link href="/signup" className="font-semibold underline">
              Créer un compte
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;